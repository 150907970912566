import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { NewDevHero } from 'components/NewDevHero'
import { NewDevHeroKeswick } from 'components/NewDevHeroKeswick'
import { NewDevelopmentProjectsKeswick } from 'components/NewDevelopmentProjectsKeswick'
import { NewDevelopmentProjects } from 'components/NewDevelopmentProjects'
import { NewDevelopmentRegistration } from 'components/NewDevelopmentRegistration'
import { Contact } from 'components/Contact'
import { Carousel } from 'react-responsive-carousel'
import { NewDevelopmentProjectsRichmond222 } from 'components/NewDevelopmentProjectsRichmond222'
import { NewDevHeroRichmond222 } from 'components/NewDevHeroRichmond222'
import { NewDevelopmentProjectsRichmond184 } from 'components/NewDevelopmentProjectsRichmond184'
import { NewDevHeroRichmond184 } from 'components/NewDevHeroRichmond184'
import { NewDevelopmentProjectsRumble155 } from 'components/NewDevelopmentProjectsRumble155'
import { NewDevHeroRumble155 } from 'components/NewDevHeroRumble155'

const DevelopmentsPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        autoPlay={false}
        adaptiveHeight={true}
        fade={true}
        swipeScrollTolerance={100}
        className="newDevsCarouselWrapper desktopOnly"
      >
        <div>
          <NewDevHeroRichmond222 />
          <NewDevelopmentProjectsRichmond222 style={{ background: '#eee' }} />
        </div>
        <div>
          <NewDevHeroRichmond184 />
          <NewDevelopmentProjectsRichmond184 style={{ background: '#eee' }} />
        </div>
        <div>
          <NewDevHeroRumble155 />
          <NewDevelopmentProjectsRumble155 style={{ background: '#eee' }} />
        </div>
      </Carousel>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        autoPlay={false}
        swipeable={false}
        swipeToSlide={false}
        adaptiveHeight={true}
        fade={true}
        swipeScrollTolerance={100}
        className="newDevsCarouselWrapper mobileOnly"
      >
        <div>
          <NewDevHero />
          <NewDevelopmentProjects style={{ background: '#eee' }} />
        </div>
        <div>
          <NewDevHeroRichmond222 />
          <NewDevelopmentProjectsRichmond222 style={{ background: '#eee' }} />
        </div>
        <div>
          <NewDevHeroRichmond184 />
          <NewDevelopmentProjectsRichmond184 style={{ background: '#eee' }} />
        </div>
        <div>
          <NewDevHeroRumble155 />
          <NewDevelopmentProjectsRumble155 style={{ background: '#eee' }} />
        </div>
      </Carousel>
      {/* <div>test</div> */}
      <Contact />
    </EllicotLayout>
  )
}

export default DevelopmentsPage
